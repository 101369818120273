var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "CRow",
        [
          _c(
            "CCol",
            { attrs: { md: "12" } },
            [
              _c(
                "Jumbotron",
                {
                  attrs: {
                    title: _vm.$t(
                      "pages.owner.playrooms.OwnerPlayroomIndex.title"
                    )
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "pages.owner.playrooms.OwnerPlayroomIndex.titleText"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _vm.showTable
            ? _c(
                "CCol",
                { attrs: { md: "9" } },
                [
                  _c("DataTable", {
                    staticClass: "data-table",
                    attrs: {
                      items: _vm.data.playrooms.items,
                      fields: [
                        {
                          key: "name",
                          label: _vm.$t(
                            "pages.owner.playrooms.OwnerPlayroomIndex.name"
                          ),
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "status",
                          label: _vm.$t(
                            "pages.owner.playrooms.OwnerPlayroomIndex.status"
                          ),
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "updatedAt",
                          label: _vm.$t(
                            "pages.owner.playrooms.OwnerPlayroomIndex.updatedAt"
                          ),
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "completedBookings",
                          label: _vm.$t(
                            "pages.owner.playrooms.OwnerPlayroomIndex.completedBookings"
                          ),
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "upcomingBookings",
                          label: _vm.$t(
                            "pages.owner.playrooms.OwnerPlayroomIndex.upcomingBookings"
                          ),
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "rating",
                          label: _vm.$t(
                            "pages.owner.playrooms.OwnerPlayroomIndex.rating"
                          ),
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "visits",
                          label: _vm.$t(
                            "pages.owner.playrooms.OwnerPlayroomIndex.visits"
                          ),
                          sorter: false,
                          filter: false
                        },
                        {
                          key: "actions",
                          label: _vm.$t("global.actions"),
                          sorter: false,
                          filter: false
                        }
                      ],
                      "table-filter-value": _vm.tableFilterValue,
                      "sorter-value": _vm.sorterValue,
                      loading: _vm.loading
                    },
                    on: {
                      "update:tableFilterValue": function($event) {
                        _vm.tableFilterValue = $event
                      },
                      "update:table-filter-value": function($event) {
                        _vm.tableFilterValue = $event
                      },
                      "update:sorterValue": function($event) {
                        _vm.sorterValue = $event
                      },
                      "update:sorter-value": function($event) {
                        _vm.sorterValue = $event
                      }
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "name",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "PublicPlayroomDetail",
                                          params: { playroomId: item.id }
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "c-tooltip",
                                              rawName: "v-c-tooltip",
                                              value: {
                                                appendToBody: true,
                                                content: item.id
                                              },
                                              expression:
                                                "{ appendToBody: true, content: item.id }"
                                            }
                                          ]
                                        },
                                        [_vm._v(_vm._s(item.name))]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "rating",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: {
                                          name: "PublicPlayroomDetail",
                                          hash: "#rating",
                                          params: { playroomId: item.id }
                                        }
                                      }
                                    },
                                    [
                                      item.rating
                                        ? _c("Rating", {
                                            attrs: {
                                              rating: {
                                                id: item.id,
                                                rating: item.rating
                                              }
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(
                                        "(" + _vm._s(item.ratingCount) + ") "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        },
                        {
                          key: "visits",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "pages.owner.playrooms.OwnerPlayroomIndex.visitsCount",
                                        {
                                          count: item.visitsLast7Days,
                                          change: item.visitsChange
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "status",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                !item.activeByAdmin && !item.activeByOwner
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.owner.playrooms.OwnerPlayroomIndex.createdAt"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                !item.activeByAdmin && item.activeByOwner
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.owner.playrooms.OwnerPlayroomIndex.publishedAt"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                item.activeByAdmin && item.activeByOwner
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.owner.playrooms.OwnerPlayroomIndex.approvedAt"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                item.activeByAdmin && !item.activeByOwner
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "pages.owner.playrooms.OwnerPlayroomIndex.deactivatedAt"
                                            )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]
                          }
                        },
                        {
                          key: "updatedAt",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.formatDate(item.updatedAt, "Pp"))
                                )
                              ])
                            ]
                          }
                        },
                        {
                          key: "actions",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c(
                                "td",
                                [
                                  _c(
                                    "CDropdown",
                                    {
                                      attrs: {
                                        color: "primary",
                                        "toggler-text": "",
                                        "data-cy": "index-actions"
                                      }
                                    },
                                    [
                                      _c("CDropdownItem", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.owner.playrooms.OwnerPlayroomIndex.show",
                                            expression:
                                              "'pages.owner.playrooms.OwnerPlayroomIndex.show'"
                                          }
                                        ],
                                        attrs: {
                                          to: {
                                            name: "PublicPlayroomDetail",
                                            params: { playroomId: item.id }
                                          }
                                        }
                                      }),
                                      _c("CDropdownItem", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.owner.playrooms.OwnerPlayroomIndex.change",
                                            expression:
                                              "'pages.owner.playrooms.OwnerPlayroomIndex.change'"
                                          }
                                        ],
                                        attrs: {
                                          to: {
                                            name:
                                              "OwnerPlayroomEditBasicInformation",
                                            params: { playroomId: item.id }
                                          }
                                        }
                                      }),
                                      _c("CDropdownItem", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.owner.playrooms.OwnerPlayroomIndex.occupationPlan",
                                            expression:
                                              "'pages.owner.playrooms.OwnerPlayroomIndex.occupationPlan'"
                                          }
                                        ],
                                        attrs: {
                                          to: {
                                            name: "OwnerOrderCalendar"
                                          }
                                        }
                                      }),
                                      _c("CDropdownItem", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.owner.playrooms.OwnerPlayroomIndex.statistics",
                                            expression:
                                              "'pages.owner.playrooms.OwnerPlayroomIndex.statistics'"
                                          }
                                        ],
                                        attrs: {
                                          to: {
                                            name: "OwnerDashboardIndex"
                                          }
                                        }
                                      }),
                                      _c("CDropdownItem", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.owner.playrooms.OwnerPlayroomIndex.downloadQr",
                                            expression:
                                              "'pages.owner.playrooms.OwnerPlayroomIndex.downloadQr'"
                                          }
                                        ],
                                        attrs: {
                                          href: _vm.downloadQrCodeUrl(item.id),
                                          target: "_blank"
                                        }
                                      }),
                                      _c("CDropdownDivider"),
                                      _c("CDropdownItem", {
                                        directives: [
                                          {
                                            name: "t",
                                            rawName: "v-t",
                                            value:
                                              "pages.owner.playrooms.OwnerPlayroomIndex.delete",
                                            expression:
                                              "'pages.owner.playrooms.OwnerPlayroomIndex.delete'"
                                          }
                                        ],
                                        staticClass: "text-danger",
                                        attrs: { "data-cy": "delete-playroom" },
                                        on: {
                                          click: function($event) {
                                            return _vm.deletePlayroom(item.id)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      1742462500
                    )
                  }),
                  _vm.data.pages > 1
                    ? _c("CPagination", {
                        attrs: {
                          pages: _vm.data.playrooms.totalPages,
                          activePage: _vm.currentPage
                        },
                        on: {
                          "update:activePage": function($event) {
                            _vm.currentPage = $event
                          },
                          "update:active-page": function($event) {
                            _vm.currentPage = $event
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c(
            "CCol",
            { attrs: { md: _vm.showTable ? 3 : 12 } },
            [
              _c(
                "CreateCard",
                {
                  attrs: {
                    loading: _vm.loading,
                    errorMessage: _vm.message,
                    title: _vm.$t(
                      "pages.owner.playrooms.OwnerPlayroomIndex.createTitle"
                    )
                  },
                  on: { handleSubmit: _vm.handleSubmit }
                },
                [
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "playroom.name",
                      field: _vm.$v.newPlayroom.name,
                      value: _vm.$v.newPlayroom.name.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.newPlayroom.name,
                          "$model",
                          $event
                        )
                      }
                    }
                  }),
                  _vm.playroomCopyOptions.length > 1
                    ? _c("ValidatedSelect", {
                        attrs: {
                          label: _vm.$t(
                            "pages.owner.playrooms.OwnerPlayroomIndex.copyProperties"
                          ),
                          translationKey: "playroom.copy",
                          options: _vm.playroomCopyOptions,
                          field: _vm.$v.newPlayroom.playroomIdToCopy,
                          value: _vm.$v.newPlayroom.playroomIdToCopy.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.newPlayroom.playroomIdToCopy,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "CModal",
            {
              attrs: {
                title: _vm.$t("forms.common.deletetitle"),
                color: "warning",
                show: _vm.showDeleteModal,
                "data-cy": "delete-modal"
              },
              on: {
                "update:show": function($event) {
                  _vm.showDeleteModal = $event
                }
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "footer",
                    fn: function() {
                      return [
                        _c("CButton", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "forms.common.cancel",
                              expression: "'forms.common.cancel'"
                            }
                          ],
                          attrs: { color: "info" },
                          on: {
                            click: function($event) {
                              _vm.showDeleteModal = false
                            }
                          }
                        }),
                        _c("CButton", {
                          directives: [
                            {
                              name: "t",
                              rawName: "v-t",
                              value: "forms.common.delete",
                              expression: "'forms.common.delete'"
                            }
                          ],
                          attrs: {
                            color: "danger",
                            "data-cy": "delete-modal-button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.confirmDelete()
                            }
                          }
                        })
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2324830189
              )
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "pages.owner.playrooms.OwnerPlayroomIndex.doYouWantToDelete"
                    )
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }